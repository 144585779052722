<template>
  <div id="certificate-app">
    <Certificate />
  </div>
</template>

<script>
import Certificate from '@/components/Certificate.vue';

export default {
  name: 'App',
  components: {
    Certificate,
  },
  data() {
    return {
      api: {
        path: '/bitrix/services/main/ajax.php',
        components: 'seoprotor:api',
        mode: 'class',
      },
    };
  },
  methods: {
    getApiUrl() {
      const { protocol, hostname, port } = window.location;
      let urlAddress = `${protocol}//${hostname}`;
      if (port.length > 0) {
        urlAddress += `:${port}`;
      }

      const url = new URL(`${urlAddress}${this.api.path}`);
      url.searchParams.set('c', this.api.components);
      url.searchParams.set('mode', this.api.mode);

      return url.toString();
    },
  },
  created() {
    const apiUrl = this.getApiUrl();
    this.$store.dispatch('setApiUrl', apiUrl);
  },
};
</script>

<style lang="scss">
  #certificate-popup {
    background: #E6FAF8;
    box-shadow: 0px 4px 40px rgba(204, 221, 219, 0.2);
    border-radius: 10px;
    width: 100%;
    max-width: 1200px;
  }
</style>
