<template>
  <section>
    <div class="scs-header">
      <h2 class="scs-title">Купить подарочный сертификат</h2>

      <p class="scs-text">
        <a href="#popup-certificate-rules" data-fancybox="" class="popup">
          Правила использования сертификата
        </a>
      </p>
    </div>

    <p class="scs-subtitle">Выберете номинал карты</p>

    <div class="row">
      <template v-for="(item, index) in list">
        <div
          class="col-md-6 col-xl-3 mb-4 mb-xl-0"
          :key="index"
        >
          <label class="scs-radio">
            <input
              type="radio"
              :value="item.id"
              v-model="selectProduct"
            />

            <div class="scs-radio__wrap">
              <div class="scs-radio__block">
                Сертификат на

                <span>{{ item.price }} руб.</span>

                <div class="scs-radio__img">
                  <img :src="item.img" :alt="`Сертификат ${item.price}`">
                </div>
              </div>
            </div>
          </label>
        </div>
      </template>
    </div>

    <div class="row justify-content-center mt-4">
      <div class="col-md-4 col-lg-3">
        <a
          href="#"
          class="scs-btn"
          @click.prevent="(selectProduct /* && privacyPolicy */) ? addBasket() : false"
        >В корзину</a>
      </div>
    </div>

    <!-- <div class="scs-bottom-text">
      <label class="scs-checkbox">
        <input
          type="checkbox"
          v-model="privacyPolicy"
        />

        <span>
          Нажимая, вы даёте разрешение на
          <a href="#personal" data-fancybox class="popup">обработку персональных данных</a>
          и соглашаетесь с
          <a href="/include/rules/rules.pdf" target="_blank">правилами посещения центра</a>
        </span>
      </label>
    </div> -->
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Certificate',
  data() {
    return {
      list: [],
      selectProduct: null,
      privacyPolicy: false,
    };
  },
  computed: {
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
  },
  methods: {
    getPriceList() {
      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getPriceListCertificate');

      axios
        .get(url)
        .then((response) => {
          this.list = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addBasket() {
      const basket = [{
        id: this.selectProduct,
        count: 1,
        type: 'Certificate',
        date: '',
        weekend: false,
      }];

      let basketStorage = JSON.parse(localStorage.getItem('basket'));
      if (!basketStorage) basketStorage = [];

      localStorage.setItem('basket', JSON.stringify([...basketStorage, ...basket]));
      window.dispatchEvent(new CustomEvent('updateBasket', {
        detail: JSON.parse(localStorage.getItem('basket')),
      }));

      window.jQuery.fancybox.open({
        src: '#add-basket-success',
        type: 'inline',
      });
    },
  },
  created() {
    this.getPriceList();
  },
};
</script>

<style lang='scss' scoped>
  .scs-header {
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 30px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -44px;
      width: calc(100% + 88px);
      height: 1px;
      background: #B9ECE7;
    }
  }

  .scs-title {
    font-weight: bold;
    font-size: 50px;
    line-height: 75px;
    text-align: center;
    color: #0DB2A2;
  }

  .scs-subtitle {
    font-weight: 500;
    font-size: 26px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 30px;
  }

  .scs-text {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }

  .scs-radio {
    display: block;

    input {
      display: none;

      &:checked + .scs-radio__wrap:before {
        background: #0DB2A2;
      }
    }

    &__wrap {
      display: flex;

      &:before {
        content: '';
        flex-shrink: 0;
        width: 23px;
        height: 23px;
        background: #FFFFFF;
        box-shadow: 0 0 0 1px #C9C9C9;
        border: 5px solid #fff;
        border-radius: 50%;
        margin-right: 15px;
      }
    }

    &__block {
      background: #FFFFFF;
      box-shadow: 0px 4px 40px rgba(204, 221, 219, 0.2);
      border-radius: 10px;
      font-size: 18px;
      line-height: 26px;
      padding: 15px 15px 120px;
      position: relative;
      width: 100%;

      span {
        display: block;
        margin-top: 15px;
        font-weight: bold;
        font-size: 34px;
        line-height: 28px;
        color: #0DB2A2;
      }

      @media (max-width: 575px) {
        text-align: center;
      }
    }

    &__img {
      position: absolute;
      bottom: 15px;
      right: 15px;
      width: 120px;
      height: 100px;

      img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
        object-position: right;

        @media (max-width: 575px) {
          object-position: center;
        }
      }

      @media (max-width: 1199px) {
        width: 180px;
        height: 100%;
      }

      @media (max-width: 991px) {
        width: 120px;
        height: 100px;
      }

      @media (max-width: 767px) {
        width: 180px;
        height: 100%;
      }

      @media (max-width: 575px) {
        width: 120px;
        height: 100px;
        right: 50%;
        transform: translateX(50%);
      }
    }
  }

  .scs-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 61px;
    background: #F39712;
    border-radius: 10px;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    transition: background-color .25s ease-in-out;

    &:hover {
      background: #F1B358;
    }
  }

  .scs-checkbox {

    input {
      display: none;

      &:checked + span:before {
        background-image: url(~@/assets/img/check.svg);
      }
    }

    span {
      display: inline-flex;

      &:before {
        content: '';
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        margin-right: 15px;
        border: 1px solid #e0e0e0;
        background-color: #fff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
        margin-top: 3px;
      }
    }
  }

  .scs-bottom-text {
    text-align: center;
    font-size: 15px;
    line-height: 26px;

    a {
      text-decoration: underline;

      &:hover,
      &:focus {
        color: #000;
        text-decoration: none;
      }
    }
  }
</style>
